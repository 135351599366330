.delete-field-space {
    padding-bottom: 30px;
}

.solid {
    opacity: 30%;
}

.delete-description-space {
    padding-bottom: 10px;
}

.delete-input-width {
    width: 560px;
}
