.appContainer {
  min-width: 1024px;
  width: 80%;
  height: 100%;
  margin: auto;
}

body {
  margin: 0px;
  background-color: #f9fafa;
}

.timezone-info {
  order: 2 !important;
  flex: 1 1 !important;
  padding-right: 16px !important;
  text-align: right !important;

  .awsui-popover__trigger-text {
    color: #0073bb !important;
  }

  .awsui-popover__content {
    overflow-y: scroll !important;
    height: 500px !important;
  }

  .awsui-popover__header {
    font-size: 16px !important;
  }
}

.timezone-mapping {
  margin-bottom: 1.5em;
}

.timezone-value {
  color: #16191f;
  font-weight: 400;
}

.timezone-label {
  color: #545b64;
  font-weight: 400;
  margin-bottom: 0.2em;
}

.upload-info {
  color: #0073bb;
}

.awsui-button-variant-primary {
  background: #c95106 !important;
}

.export-csv-btn {
  color: #545b64 !important;
}

.timezone-list {
  list-style-type: none !important;
  padding-left: 0px !important;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
