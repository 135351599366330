.margin-bottom-top-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin-bottom-top-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-top-bottom-auto {
  margin-top: auto;
  margin-bottom: auto;
}
