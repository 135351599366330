.accessDenied {
  padding: 40px;
  height: 700px;
  text-align: center;
}

.bigTitle {
  font-size: 50px;
  margin: 25px;
}

.description {
  font-size: 20px;
  margin: 10px;
}
